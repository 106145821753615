import React from 'react';
import { Link } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import { HeaderSimple } from '../components/headers';
import LandingImageOne from '../components/images/LandingImageTwo';
import Layout from '../layout';

import LandingImageThree from '../components/images/LandingImageThree';
import SEO from '../components/SEO';
import LogoReel from '../components/images/logoreel';
import BgVideoTestimonial from '../components/bgVideoTestimonial';
import ImageQuote from '../components/images/ImageQuote';
import ItImage from '../components/images/ItImage';
import ItImageTwo from '../components/images/ItImageTwo';
import Clients from '../components/clients';
import Quote from '../components/Quotes';
import BookDiscoveryCall from '../components/BookDiscoveryCall';

const It = () => (
  <Layout>
    <SEO />
    <div className="bg-white py-5" />
    <div className="container-fluid pt-5 mx-auto">
      <div className="container text-center text-md-left mx-auto">
        <span className="p-3 bg-themeSecondary m-3" />
        <h2 className=" display-2 align-self-center font-weight-bold  text-neutralDark">
          We know what IT systems work for growing small businesses
        </h2>
        <h2 className=" intro align-self-center font-weight-light  text-neutralTertiary">
          We work with small business and startups, helping them navigate the ever changing world of IT and
          Integrations. We realise that digital systems are playing an ever increasing role in business.
        </h2>
        <br />
        <p className="lead">
          <span className="font-weight-bold">
            Whether you need to add a Facebook pixel to make better decisions about your campaigns. Or you need to add
            Google analytics to see where your users are dropping off. To see what content works or not. We help you and
            guide you in these processes.
          </span>
          <br />
          <br />
          We understand that its no longer about building a website and leaving it as is. As you grow, you need powerful
          technology that can scale with you. Its hard to know what types of hosting to go for, and what stage to
          upgrade. What type of server do you need for the type of website or web application that you have?
          <br />
          <br />
          You might even ask yourself, how can we integrate Microsoft 365, or G Suite into our IT systems? having an
          ecommerce site means setting up Paypal express checkout for woocommerce, can be challenging. What is the
          process of setting up Stripe checkout, or any other payment gateways?
          <br />
          <br />
          All this has become necessary and crucial to the success of your business. We understand this.
        </p>
      </div>

      <div className="row p-0 bg-white align-items-start m-0">
        <div className="col-md-7  image-link-hover p-0">
          <div className="card case-study-height-auto case-study border-0 rounded-0 text-white">
            <ItImage />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row h-100 p-2 p-md-5">
          <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-3 inline-block px-3 py-5">
            <span className="p-3 bg-themeSecondary m-3" />
            How we help
          </p>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Virtual Private Servers</li>
              <li className="list-group-item case-text text-themePrimary border-0">Email Configurations</li>
              <li className="list-group-item case-text text-themePrimary border-0">Microsoft Office 365</li>
              <li className="list-group-item case-text text-themePrimary border-0">Google G Suite</li>
              <li className="list-group-item case-text text-themePrimary border-0">Ecommerce Payment Gateways</li>
              <li className="list-group-item case-text text-themePrimary border-0">Domain Guardianship </li>
            </ul>
          </div>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Security, encryption and SSL</li>
              <li className="list-group-item case-text text-themePrimary border-0">Analytics and Tracking</li>
              <li className="list-group-item case-text text-themePrimary border-0">Technical SEO Technology</li>
              <li className="list-group-item case-text text-themePrimary border-0">Mailchimp Integrations</li>
              <li className="list-group-item case-text text-themePrimary border-0">HubSpot Integrations</li>
              <li className="list-group-item case-text text-themePrimary border-0">Marketing Automation</li>
            </ul>
          </div>
        </div>
      </div>
      <Clients clientImage={<ItImageTwo />} text="Turn more leads into sales" />
      <Quote
        video={<BgVideoTestimonial />}
        name="Andile Siziba"
        job="Director of Neurophysiology | MediServices Healthcare"
        quoteOne="Since approaching Onai Intelligence to build our website and re-brand our business, we have since seen an
                increase in our digital ROI."
        quoteTwo=" Onai has since extended our website to include other processes as well as their other Digital, IT and
                SEO services. I would highly recommend Onai to any small business"
      />
      <BookDiscoveryCall />
    </div>
  </Layout>
);

export default It;
